<template>
    <div>
        <b-row class="m-0">
            <b-col class="p-0 pe-1">
                <div>
                    <div class="bg-purple p-3 rounded-top">
                        <div class="d-flex align-items-center justify-content-between text-white">
                            <div class="fs-5">
                                Configurações da conta de WhatsApp
                            </div>
                            <div id="threeDotsWrapper">
                                <b-icon class="bg-white rounded-circle text-dark px-2" font-scale="2.5" icon="three-dots" role="button" v-b-modal.editData></b-icon>
                                <b-tooltip target="threeDotsWrapper" triggers="hover" placement="bottom" custom-class="top-0" boundary="document" variant="light">
                                    Editar Dados
                                </b-tooltip>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center my-4">
                            <b-avatar :src="channel.avatar" class="text-white bg-secondary border border-3" size="6em"></b-avatar>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <div class="text-white fs-5">
                                {{ channel.name }}
                            </div>
                            <div class="text-light-purple" v-if="channel.whatsappStatus">
                                "{{ channel.whatsappStatus }}"
                            </div>
                        </div>
                    </div>
                    <div class="bg-white p-3 rounded-bottom text-purple">
                        <div class="d-flex align-items-center border-bottom pb-3 px-2">
                            <b-row class="m-0 d-flex">
                                <b-col class="p-0 d-flex align-items-center flex-basis-content me-2" cols="1">
                                    <b-icon font-scale="1.75" icon="whatsapp"></b-icon>
                                </b-col>
                                <b-col class="p-0 d-flex flex-column justify-content-center">
                                    <div class="fw-bold" style="font-size:1.2em">
                                        {{ channel.channelNumber }}
                                    </div>
                                    <div class="text-purple">
                                        <span class="d-inline-flex align-items-center" v-if="channel.status == 'authenticated'">
                                            Online
                                            <b-icon class="ms-1 mt-1" variant="green" icon="circle-fill" font-scale=".5"></b-icon>
                                        </span>
                                        <span class="d-inline-flex align-items-center" v-else>
                                            Offline
                                            <b-icon class="ms-1 mt-1 " variant="danger" icon="circle-fill" font-scale=".5"></b-icon>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="d-flex align-items-center border-bottom py-3 px-2">
                            <b-row class="m-0 d-flex">
                                <b-col class="p-0 d-flex align-items-center flex-basis-content me-2" cols="1">
                                    <b-icon font-scale="1.75" icon="clock"></b-icon>
                                </b-col>
                                <b-col class="p-0 d-flex flex-column justify-content-center">
                                    <div class="fw-bold">
                                        A última comunicação do canal com o contato:
                                    </div>
                                    <div class="text-capitalize" v-if="channelConfig?.lastSendedMessage">
                                        {{ channelConfig.lastSendedMessage | date('MMM DD [●] HH:mm:ss')}}
                                    </div>
                                    <div v-else>- - - -</div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="d-flex align-items-center pt-3 px-2">
                            <b-row class="m-0 d-flex">
                                <b-col class="p-0 d-flex align-items-center flex-basis-content me-2" cols="1">
                                    <b-icon font-scale="1.75" icon="clock"></b-icon>
                                </b-col>
                                <b-col class="p-0 d-flex flex-column justify-content-center">
                                    <div class="fw-bold">
                                        A última comunicação do contato com o canal:
                                    </div>
                                    <div class="text-capitalize" v-if="channelConfig?.lastReceivedMessage">
                                        {{ channelConfig.lastReceivedMessage | date('MMM DD [●] HH:mm:ss') }}
                                    </div>
                                    <div v-else>- - - -</div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                <!-- {{ channelConfig.operationalChannelEnabled }} {{ secondaryChannels.length }} -->
                </div>
                <div class="bg-white rounded text-purple mt-3" v-if="secondaryChannels?.length">
                    <div class="p-3 fs-5">
                        Canais Secundários
                    </div>
                    <div class="p-3 border-top" v-for="item in secondaryChannels" :key="item._id">
                        <div class="hstack gap-2">
                            <span>
                                {{ item.channelNumber }}
                            </span>
                            <span>
                                -
                            </span>
                            <span class="d-inline-flex align-items-center" v-if="item.status == 'authenticated'">
                                Online
                                <b-icon class="ms-1 mt-1" variant="green" icon="circle-fill" font-scale=".5"></b-icon>
                            </span>
                            <span class="d-inline-flex align-items-center" v-else>
                                Offline
                                <b-icon class="ms-1 mt-1 " variant="danger" icon="circle-fill" font-scale=".5"></b-icon>
                            </span>
                        </div>
                        <!-- <div v-if="item.channelConfig?.operationalChannelEnabled"> -->
                            <div v-if="item.channelConfig">
                                <b-form-checkbox class="d-flex pt-3" v-model="item.channelConfig.operationalChannelEnabled">
                                    <div class="ms-2">
                                        Ativar Recurso de Canal Operacional
                                    </div>
                                </b-form-checkbox>
                            <b-form-group class="pt-3" label-class="fw-bold" label="Direcionar para:">
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="item.channelConfig.operationalChannel.action">
                                    <b-form-select-option value="redirectDepartment" v-if="!channelConfig.modules.simultaneousAttendanceAmongDifferentChannels">Departamento</b-form-select-option>
                                    <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="mt-2" label-class="fw-bold" :label="`Selecione o ${ item.channelConfig.operationalChannel.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="item.channelConfig.operationalChannel.action">
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="item.channelConfig.operationalChannel.id" v-if="item.channelConfig.operationalChannel.action == 'redirectDepartment'">
                                    <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                </b-form-select>
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="item.channelConfig.operationalChannel.id" v-else-if="item.channelConfig.operationalChannel.action == 'redirectOperator'">
                                    <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col class="p-0 ps-2">
                <div>
                    <div class="bg-white rounded text-purple ">
                        <div class="d-flex border-bottom p-3">
                            <div class="fs-5">
                                Temporizações
                            </div>
                        </div>
                        <div class="p-3 vstack gap-3" v-if="channelConfig">
                            <!-- <b-form-checkbox v-model="channelConfig.dontFinishWithTime">
                                <div class="ms-2">
                                    Não finalizar atendimento após inatividade do contato/operador
                                </div>
                            </b-form-checkbox> -->
                            <b-form-group label="Tempo de espera de resposta:" label-class="fw-bold pb-0" v-if="!channelConfig.dontFinishWithTime">
                                <div class="pb-1">
                                    Verifica inatividade de interação entre contato e operador
                                </div>
                                <b-input-group>
                                    <b-form-input class="border-grey" v-model="channelConfig.waitTime" type="number"/>
                                    <template #append>
                                        <div class="bg-grey h-100 d-flex align-items-center rounded-end px-2 border">
                                            Minutos
                                        </div>
                                    </template>
                                </b-input-group>
                            </b-form-group>
                            <div class="vstack gap-2" v-if="!channelConfig.operationalChannelEnabled">
                                <b-form-group label="Tempo de inatividade do contato:" label-class="fw-bold pb-0">
                                    <div class="pb-1">
                                        Verifica inatividade do contato durante o Auto Atendimento
                                    </div>
                                    <b-input-group>
                                        <b-form-input class="border-grey" v-model="channelConfig.inactiveTime" type="number"/>
                                        <template #append>
                                            <div class="bg-grey h-100 d-flex align-items-center rounded-end px-2 border">
                                                Minutos
                                            </div>
                                        </template>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group label="Ação a ser realizada sendo ultrapassado o tempo em Auto Atendimento:" label-class="small-text">
                                    <b-form-select class="border-grey w-100 p-2 rounded" v-model="channelConfig.forwardAttInactivity">
                                        <b-form-select-option :value="false">Finalizar Atendimento</b-form-select-option>
                                        <b-form-select-option :value="true">Encaminhar Atendimento para Operador ou Equipe</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                                <div v-if="channelConfig.forwardAttInactivity">
                                    <b-form-group class="mb-2" label="Encaminhar para:">
                                        <b-form-select class="border-grey w-100 p-2 rounded" v-model="channelConfig.forwardAttInactivityEnabled.action">
                                            <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                            <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group :label="channelConfig.forwardAttInactivityEnabled.action=='redirectDepartment' ? 'Departamento' : 'Operador'" v-if="channelConfig.forwardAttInactivityEnabled.action">
                                        <b-form-select class="border-grey w-100 p-2 rounded" v-model="channelConfig.forwardAttInactivityEnabled.id" v-if="channelConfig.forwardAttInactivityEnabled.action=='redirectDepartment'">
                                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select class="border-grey w-100 p-2 rounded" v-model="channelConfig.forwardAttInactivityEnabled.id" v-if="channelConfig.forwardAttInactivityEnabled.action=='redirectOperator'">
                                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div v-if="channelConfig.modules.broadcastList">
                                <b-form-group label="Tempo de espera de retorno de campanha:" label-class="fw-bold pb-0">
                                    <div class="pb-1">
                                        Verifica o tempo de espera para finalização da janela de campanha
                                    </div>
                                    <b-input-group>
                                        <b-form-input class="border-grey" v-model="channelConfig.campaignWaitingTime" type="number"/>
                                        <template #append>
                                            <div class="bg-grey h-100 d-flex align-items-center rounded-end px-2 border">
                                                Minutos
                                            </div>
                                        </template>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                            <b-form-group label="Tempo de tolerância:" label-class="fw-bold pb-0" v-if="!channelConfig.dontFinishWithTime">
                                <div class="pb-1">
                                    Tolerância para abrir novo protocolo após fim de atendimento
                                </div>
                                <b-input-group>
                                    <b-form-input class="border-grey" v-model="channelConfig.attToleranceTime" min="0" type="number"></b-form-input>
                                    <template #append>
                                        <div class="bg-grey h-100 d-flex align-items-center rounded-end px-2 border">
                                            Segundos
                                        </div>
                                    </template>
                                </b-input-group>
                            </b-form-group>
                        </div>
                        <div class="p-4 d-flex justify-content-center" v-else>
                            <b-spinner></b-spinner>
                        </div>
                    </div>
                    <div class="bg-white rounded text-purple mt-3">
                        <div class="d-flex border-bottom p-3">
                            <div class="fs-5">
                                Configurações Adicionais
                            </div>
                        </div>
                        <div class="px-3 d-flex flex-column" v-if="channelConfig">
                            <b-form-checkbox class="d-flex border-bottom py-2" v-model="channelConfig.privacy">
                                <div class="ms-2">
                                    Ativar Privacidade
                                </div>
                            </b-form-checkbox>
                            <b-form-checkbox class="d-flex border-bottom py-2" v-model="channelConfig.nicknameActivated">
                                <div class="ms-2">
                                    Ativar "Apelido" do Operador nas Mensagens de Atendimento
                                </div>
                            </b-form-checkbox>
                            <!-- <b-form-checkbox class="d-flex border-bottom py-2" v-model="channelConfig.nicknameActivatedGroupsOnly">
                                <div class="ms-2">
                                    Ativar "Apelido" do Operador nas Mensagens de Grupo
                                </div>
                            </b-form-checkbox> -->
                            <div class="border-bottom py-2">
                                <b-form-checkbox class="d-flex" v-model="channelConfig.operationalChannelEnabled">
                                    <div class="ms-2">
                                        Ativar Recurso de Canal Operacional
                                    </div>
                                </b-form-checkbox>
                                <div v-if="channelConfig.operationalChannelEnabled">
                                    <b-form-group class="pt-3" label-class="fw-bold" label="Direcionar para:">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="channelConfig.operationalChannel.action">
                                            <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                            <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="mt-2" label-class="fw-bold" :label="`Selecione o ${ channelConfig.operationalChannel.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="channelConfig.operationalChannel.action">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="channelConfig.operationalChannel.id" v-if="channelConfig.operationalChannel.action == 'redirectDepartment'">
                                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="channelConfig.operationalChannel.id" v-else-if="channelConfig.operationalChannel.action == 'redirectOperator'">
                                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="py-2 border-bottom">
                                <b-form-checkbox class="d-flex" v-model="channelConfig.enableCustomerPortfolio">
                                    <div class="ms-2">
                                        Ativar Recurso de Carteira de Clientes
                                    </div>
                                </b-form-checkbox>
                                <div v-if="channelConfig.enableCustomerPortfolio">
                                    <b-form-group class="pt-3" label-class="fw-bold" label="Caso atendimento offline, encaminhar para:">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="channelConfig.customerPortfolioEnabled.action">
                                            <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                            <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                            <b-form-select-option value="operatorQueue">Nenhum</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="mt-2" label-class="fw-bold" :label="`Selecione o ${ channelConfig.customerPortfolioEnabled?.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="channelConfig.customerPortfolioEnabled?.action && channelConfig.customerPortfolioEnabled?.action != 'operatorQueue'">
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="channelConfig.customerPortfolioEnabled.id" v-if="channelConfig.customerPortfolioEnabled?.action == 'redirectDepartment'">
                                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="channelConfig.customerPortfolioEnabled.id" v-else-if="channelConfig.customerPortfolioEnabled?.action == 'redirectOperator'">
                                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="py-2 border-bottom" v-if="channelConfig.modules">
                                <b-form-checkbox :class="`d-flex`" v-model="channelConfig.modules.askForEmailAtFirstAttendance">
                                    <div class="ms-2">
                                        Solicitar endereço de email do contato em seu primeiro atendimento
                                    </div>
                                </b-form-checkbox>
                                <div class="mt-2" v-if="channelConfig.modules.askForEmailAtFirstAttendance && channelConfig.strings.find(el => el.code == 10)">
                                    <b-form-group class="mt-3" label="Mensagem de Solicitação:" label-class="fw-bold pb-0">
                                        <b-form-input class="border-grey" v-model="channelConfig.strings.find(el => el.code == 10).string"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-checkbox :class="`d-flex py-2 border-bottom`" v-model="channelConfig.enableCustomerPortfolioMenu" v-if="!channelConfig.operationalChannelEnabled && channelConfig.enableCustomerPortfolio">
                                <div class="ms-2">
                                    Ativar Chatbot para clientes em Carteira
                                </div>
                            </b-form-checkbox>
                            <div class="border-bottom" v-if="channelConfig.modules?.satisfactionSurvey">
                                <b-form-checkbox class="d-flex py-2" v-model="channelConfig.enableSatisfactionSurvey">
                                    <div class="ms-2">
                                        Ativar Pesquisa de Satisfação após atendimento finalizado
                                    </div>
                                </b-form-checkbox>
                                <b-form-group class="ms-4 mb-2" label="Desativar Pesquisa para os canais:" label-class="fw-semibold" v-if="channelConfig.enableSatisfactionSurvey && secondaryChannels?.length">
                                    <v-select class="customSelect" placeholder="Selecione os canais para desativar a Pesquisa" label="name" multiple :reduce="channel => channel._id" :options="[channel, ...secondaryChannels]" v-model="channelConfig.disableSatisfactionSurveyChannels" />
                                </b-form-group>
                            </div>
                            <b-form-checkbox class="d-flex py-2" v-model="channelConfig.disableOfflineOperators">
                                <div class="ms-2">
                                    Desativar Fila de Espera
                                </div>
                            </b-form-checkbox>
                            <div class="mb-2 py-2 border-bottom" v-if="channel && channelConfig.disableOfflineOperators && secondaryChannels?.length">
                                <b-form-group label="Canais com fila desativada:">
                                    <v-select label="channelNumber" multiple :reduce="channelNumber => channelNumber?._id" :options="[{ _id: channel._id, channelNumber: channel.channelNumber }, ...secondaryChannels]" v-model="channelConfig.disableOfflineOperatorsChannels" />
                                </b-form-group>
                            </div>
                            <!-- <b-form-checkbox class="d-flex py-2 border-top" v-model="channelConfig.enablevCardFirstInteraction">
                                <div class="ms-2">
                                    Enviar mensagem de notificação com vCard na primeira interação do contato
                                </div>
                            </b-form-checkbox> -->
                        </div>
                        <div class="p-4 d-flex justify-content-center" v-else>
                            <b-spinner></b-spinner>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <div class="bg-white w-100 p-4 mt-3 d-flex justify-content-center">
            <b-button class="me-2 text-white" variant="green" @click="saveConfig()">Salvar</b-button>
            <!-- <b-button class="border" variant="secondary-outline">Cancelar</b-button> -->
        </div>
        <b-modal id="editData" ref="editData" body-class="p-0" hide-footer hide-header>
            <div class="bg-purple rounded-top text-white py-3">
                <div class="px-3 d-flex justify-content-between">
                    <div class="fs-5 d-flex align-items-center">
                        Editar Dados
                    </div>
                    <div class="d-flex align-items-center">
                        <b-icon icon="x" font-scale="1.75" role="button" @click="$refs['editData'].hide()"></b-icon>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-center py-5">
                    <div class="position-relative">
                        <!-- <input type="file" id="imgupload" style="display:none" @input="selectAvatar"/> -->
                        <b-form-file id="imgupload" style="display:none" accept="image/*" @input="selectAvatar"></b-form-file>
                        <label class="position-absolute" style="z-index:1; right: 0em" for='imgupload'>
                            <b-icon id="tooltip-select-avatar" class="text-dark bg-white rounded-circle p-2" font-scale="2" icon="pencil-fill" role="button"></b-icon>
                            <b-tooltip target="tooltip-select-avatar" triggers="hover" custom-class="top-0" boundary="document" placement="bottom">
                                Alterar Avatar
                            </b-tooltip>
                        </label>
                        <b-avatar :src="channel.avatar" class="text-white bg-secondary border border-3" size="6em"></b-avatar>
                    </div>
                </div>
            </div>
            <div class="bg-white p-3 pb-4 border-bottom vstack gap-3" v-if="channel?.apiType !== 'business'">
                <!-- <template v-if="channel?.apiType === 'business'">
                    <b-form-group label="Editar Nome do Canal:">
                        <b-form-input v-model="channel.name"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Status:">
                        <b-form-input v-model="channel.whatsappStatus"></b-form-input>
                    </b-form-group>
                </template> -->
                <template v-if="channel?.apiType !== 'business'">
                    <b-form-group label="Descrição:">
                        <b-form-input v-model="channel.description"></b-form-input>
                    </b-form-group>
                    <b-form-group label="CEP:">
                        <b-form-input v-maska="'#####-###'" @input="setAddressByCep" v-model="channel.zipcode"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Endereço:">
                        <b-form-input v-model="channel.address"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Email:">
                        <b-form-input type="email" v-model="channel.companyEmail"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Site:">
                        <b-form-input v-model="channel.companyWebsite"></b-form-input>
                    </b-form-group>
                </template>
            </div>
            <div class="bg-white rounded-bottom p-3">
                <b-button class="me-2 text-white" variant="green" @click="saveChannel()">Salvar</b-button>
                <b-button class="border" variant="secondary-outline" @click="$refs['editData'].hide()">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import utils from '../utils/utils.js'
import api from '../services/apiService.js'
import { getToken } from '../services/userService.js'

export default {
    props: [
        'user', 'socket'
    ],
    mounted() {
        this.onCloseModal()
        this.$nextTick(function() {
            this.getChannel()
            this.getChannelConfig()
            this.getSecondaryChannels()
            this.getDepartments()
            this.getOperators()
        })
    },
    data() {
        return {
            channel: {},
            secondaryChannels: [],
            channelConfig: null,
            departments: [],
            operators: [],
            avatarChanged: false,
            avatarFile: null
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.avatarChanged = false
                this.avatarFile = null
            })
        },
        async getChannel() {
            let resp = await api.getChannel(this.user.channelId || this.user.roleId)
            // console.log('resp channel',resp)
            if(resp.statusCode!=200) {
                return null
            } else {
                return this.channel = resp.channel.channel
            }
        },
        async getChannelConfig() {
            let resp = await api.getChannelConfig(this.user.channelId || this.user.roleId)
            // console.log('resp channelConfig',resp)
            if(!resp.channelConfig.customerPortfolioEnabled)
                resp.channelConfig.customerPortfolioEnabled = {
                    id: null,
                    action: "operatorQueue"
                }

            if(!resp.channelConfig.disableOfflineOperatorsChannels)
                resp.channelConfig.disableOfflineOperatorsChannels = []

            if(!resp.channelConfig.enableSatisfactionSurvey)
                resp.channelConfig.enableSatisfactionSurvey = false

            if(resp && resp.statusCode == 200) {
                this.channelConfig = resp.channelConfig
                if(!this.channelConfig.strings.find(el => el.code == 10)) {
                    this.channelConfig.strings.push({
                        code: 10,
                        string: "Informe seu endereço de email"
                    })
                }
            }
        },
        async getOperators() {
            let resp = await api.getOperators(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode!=200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }
            this.operators = resp.operators
            console.log('operators',this.operators)
        },
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId, true)
            if(resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            // if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
            //     resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        async getSecondaryChannels() {
            let resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            // console.log('resp secChannels',resp)
            if(resp.statusCode != 200) {
                this.secondaryChannels = []
            } else {
                this.secondaryChannels = resp.channels
                await this.secondaryChannels.map(async (el) => {
                    let config = await api.getChannelConfig(el._id)
                    this.$set(el,'channelConfig',config.channelConfig)
                    return el
                })
            }
        },
        selectAvatar(e) {
            console.log(e)
            if (e.size > 10000000) {
                let input = document.querySelector('#imgupload')
                input.reset()
                return this.$bvToast.toast(`O tamanho máximo permitido é de 10 MB`, {
                    title: 'Erro',
                    autoHideDelay: 3000,
                    appendToast: false,
                    solid:true,
                    variant: 'danger'
                })
            }
            const reader = new FileReader()
            reader.readAsDataURL(e)
            reader.onload = ()=>{
                const dataURL = reader.result
                this.channel.avatar = dataURL.replace('base64','charset=utf-8;base64')
            }
            this.avatarChanged = true
            this.avatarFile = e
        },
        async uploadAvatar() {
            const formData = new FormData();
            let file = this.avatarFile
            file.filename = file.name
            formData.append('file',file)
            let typefile = file['type'].split('/')[0]
            let token = getToken()
            let headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.channelConfig.channelId }
            let uploadResp = await api.upload(formData,headers)
            if(uploadResp) {
                console.log('uploadResp',uploadResp)
                let url = uploadResp.file.uploadDir
                // console.log('url',url)
                this.channel.avatar = url
                return true
            }
            return false
        },
        async saveConfig() {
            // console.log('channelConfig',this.channelConfig)
            if(!this.channelConfig.customerPortfolioEnabled.action) {
                this.channelConfig.customerPortfolioEnabled.action = "operatorQueue"
            }

            if(this.channelConfig.disableOfflineOperatorsChannels?.length && !this.secondaryChannels.length)
                this.channelConfig.disableOfflineOperatorsChannels = [null]

            let saveConfig = await api.updateChannelConfig(this.channelConfig)
            console.log('saveConfig',saveConfig)
            if(this.secondaryChannels?.length) {
                this.secondaryChannels.forEach(async (el) => {
                    if(el.channelConfig) {
                        // if(this.channelConfig.enableOperationalChannel)
                        //     el.channelConfig.enableOperationalChannel = this.channelConfig.enableOperationalChannel
                        el.channelConfig.nicknameActivated = this.channelConfig.nicknameActivated
                        el.channelConfig.customerPortfolioEnabled = this.channelConfig.customerPortfolioEnabled
                        el.channelConfig.enableCustomerPortfolio = this.channelConfig.enableCustomerPortfolio
                        el.channelConfig.enablevCardFirstInteraction = this.channelConfig.enablevCardFirstInteraction
                        
                        if(this.channelConfig.privacy)
                            el.channelConfig.privacy = this.channelConfig.privacy
                        if(this.channelConfig.waitTime)
                            el.channelConfig.waitTime = this.channelConfig.waitTime
                        if(this.channelConfig.inactiveTime)
                            el.channelConfig.inactiveTime = this.channelConfig.inactiveTime
    
                        await api.updateChannelConfig(el.channelConfig)
                        this.socket.emit("update_channel_config",el.channelConfig)
                    }
                })
            }
            let msg = {}
            if(saveConfig.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro, tente novamente mais tarde!',
                    success: false,
                }
            } else {
                this.$emit('channelConfig',this.channelConfig)
                this.socket.emit("update_channel_config",this.channelConfig)
                msg = {
                    text:'Canal alterado com sucesso!',
                    success: true,
                }
            }
            this.$emit('msg',msg)
        },
        async saveChannel() {
            if(this.channel.companyWebsite)
                if(!utils.isValidHttpUrl(this.channel.companyWebsite)) {
                    let msg = {
                        text:'O site informado é inválido!',
                        success: false,
                    }
                    this.$emit('msg',msg)
                    return
                }


            if(this.avatarChanged) {
                let respUpload = await this.uploadAvatar()
                // console.log('respUpload',respUpload)
                if(!respUpload) {
                    let msg = {
                        text:'Ocorreu um erro ao alterar avatar!',
                        success: false,
                    }
                    this.$emit('msg',msg)
                    this.channel.avatar = ''
                }
            }
            let save = await api.updateChannel(this.channel)
            // console.log('save',save)
            let msg = {}
            if(save.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro, tente novamente mais tarde!',
                    success: false,
                }
            } else {
                msg = {
                    text:'Respostas alteradas com sucesso!',
                    success: true,
                }
            }
            this.$emit('msg',msg)
            this.$refs['editData'].hide()
        },
        async setAddressByCep(e) {
            e = e.replace(/\D+/g, '')
            if(e.length == 8) {
                this.$viaCep.buscarCep(e).then((obj) => {
                    if(!obj.erro) {
                        this.channel.address = `${obj.logradouro}, ${obj.bairro}, ${obj.localidade}, ${obj.uf}`
                        if(obj.complemento)
                            this.channel.complement = obj.complemento
                    }
                });
            }
        },
    }
}
</script>